"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const TrackingManager_1 = require("../../utils/TrackingManager");
const vue_property_decorator_1 = require("vue-property-decorator");
let BaseButton = class BaseButton extends vue_property_decorator_1.Vue {
    /**
     * State properties
     */
    /**
     * Methods
     */
    onClick(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isRedirect = this.url && !this.url.startsWith('#');
            if (this.isTrackable && this.name) {
                const trackingTimeoutPromise = new Promise((resolve) => {
                    setTimeout(resolve, 3000);
                });
                // delay redirects to handle tracking
                if (isRedirect) {
                    event.preventDefault();
                }
                // give tracking up to 3 additional seconds, then continue
                yield Promise.any([trackingTimeoutPromise, TrackingManager_1.tracking.action(this.name)]);
                if (isRedirect) {
                    location.href = this.url;
                }
            }
        });
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], BaseButton.prototype, "name", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: false })
], BaseButton.prototype, "disabled", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: true })
], BaseButton.prototype, "isExpanded", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: true })
], BaseButton.prototype, "isTrackable", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], BaseButton.prototype, "url", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], BaseButton.prototype, "target", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'large' })
], BaseButton.prototype, "size", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'primary' })
], BaseButton.prototype, "type", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'button' })
], BaseButton.prototype, "nativeType", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Emit)('click')
], BaseButton.prototype, "onClick", null);
BaseButton = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'BaseButton' })
], BaseButton);
exports.default = BaseButton;
