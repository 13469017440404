"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let EcoRating = class EcoRating extends vue_property_decorator_1.Vue {
    // Computed properties
    get getLocale() {
        return this.locale ? this.locale.replace('-', '_') : '';
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], EcoRating.prototype, "locale", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], EcoRating.prototype, "market", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], EcoRating.prototype, "currentEmissions", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], EcoRating.prototype, "value", void 0);
EcoRating = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'EcoRating' })
], EcoRating);
exports.default = EcoRating;
