"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultBodytype = exports.getDefaultMotorization = exports.singleClassSelectorFirstStep = void 0;
exports.getMotorizationPayload = getMotorizationPayload;
exports.vehicleDivision = vehicleDivision;
exports.formatFuelType = formatFuelType;
exports.getMainFuelType = getMainFuelType;
exports.capitalize = capitalize;
exports.getDefaultImage = getDefaultImage;
const utils_1 = require("@/store/modules/vmds/utils");
const types_1 = require("@/store/modules/vmds/types");
const constants_1 = require("@/store/modules/vehicleSelector/constants");
const utils_2 = require("@/utils");
const utils_3 = require("@/utils");
exports.singleClassSelectorFirstStep = constants_1.STEPS.THREE_CHOOSE_MOTORIZATION;
function getMotorizationPayload(motorization, applicationBrand) {
    var _a;
    const { baumuster, body, brand, ccsId, changeYear, combustionEnginePower, condition, consumption, currency, division, electricEnginePower, emissions, engine, engineConcept, environmentalDiscount, listPrice, modelSeriesFacelift, modelYear, name, nst, taxes, } = motorization;
    const productGroup = motorization === null || motorization === void 0 ? void 0 : motorization.productGroup;
    let vmdsDivision;
    if (productGroup === 'PASSENGER_CAR') {
        vmdsDivision = 'pc';
    }
    else if (productGroup === 'VAN') {
        vmdsDivision = 'van';
    }
    const mapTaxes = (0, utils_1.mapTaxDetails)(taxes);
    const charges = (0, utils_1.getPriceCharges)(mapTaxes);
    const isVans = applicationBrand === utils_3.APP_BRAND.VANS;
    const defaultDivision = isVans ? 'van' : 'pc';
    return {
        vehicleConfiguration: {
            baumuster,
            brand: brand ? brand.toLowerCase() : 'mercedes-benz',
            changeYear: changeYear || '',
            division: (_a = vmdsDivision !== null && vmdsDivision !== void 0 ? vmdsDivision : division) !== null && _a !== void 0 ? _a : defaultDivision,
            facelift: modelSeriesFacelift,
            modelYear: modelYear || '',
            nst: nst || null,
        },
        condition: {
            condition: condition || 'new',
        },
        technicalData: getTechnicalData(combustionEnginePower, electricEnginePower, emissions, engineConcept, body, engine, consumption),
        name,
        productCode: ccsId,
        prices: [
            {
                id: 'grossListPrice',
                currency,
                rawValue: listPrice,
                charges,
            },
            {
                id: 'baseListPrice',
                currency,
                rawValue: listPrice,
                charges,
            },
        ],
        additionalValues: getAdditionalValues(environmentalDiscount),
    };
}
function getCo2WLTPEmissionForEngineType(emissions, engineConcept) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (engineConcept) {
        case types_1.EngineConcept.COMBUSTION:
        case types_1.EngineConcept.MILD_HYBRID:
        case types_1.EngineConcept.ELECTRIC:
            return (_d = (_c = (_b = (_a = emissions === null || emissions === void 0 ? void 0 : emissions.wltp) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.primaryEngine) === null || _c === void 0 ? void 0 : _c.individual) === null || _d === void 0 ? void 0 : _d.toString();
        case types_1.EngineConcept.PLUGIN_HYBRID:
            return (_h = (_g = (_f = (_e = emissions === null || emissions === void 0 ? void 0 : emissions.wltp) === null || _e === void 0 ? void 0 : _e.weighted) === null || _f === void 0 ? void 0 : _f.primaryEngine) === null || _g === void 0 ? void 0 : _g.individual) === null || _h === void 0 ? void 0 : _h.toString();
        default:
            return null;
    }
}
function vehicleDivision(productGroup) {
    switch (productGroup) {
        case types_1.ProductGroup.VAN:
            return 'van';
        case types_1.ProductGroup.PASSENGER_CAR:
            return 'car';
        default:
            return '';
    }
}
function getElectricConsumptionWltp(wltp) {
    var _a, _b, _c, _d, _e, _f;
    const hasSecondaryEngine = (_c = (_b = (_a = wltp === null || wltp === void 0 ? void 0 : wltp.chargeDepleteWeighted) === null || _a === void 0 ? void 0 : _a.secondaryEngine) === null || _b === void 0 ? void 0 : _b.individual) === null || _c === void 0 ? void 0 : _c.toString();
    const hasOnlyPrimaryEngine = (_f = (_e = (_d = wltp === null || wltp === void 0 ? void 0 : wltp.total) === null || _d === void 0 ? void 0 : _d.primaryEngine) === null || _e === void 0 ? void 0 : _e.max) === null || _f === void 0 ? void 0 : _f.toString();
    return hasSecondaryEngine || hasOnlyPrimaryEngine;
}
function getTechnicalData(combustionEnginePower, electricEnginePower, emissions, engineConcept, body, engine, consumption) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return [
        {
            id: 'combustionPowerKw',
            value: (combustionEnginePower === null || combustionEnginePower === void 0 ? void 0 : combustionEnginePower.toString()) || null,
        },
        {
            id: 'electricPowerKw',
            value: (electricEnginePower === null || electricEnginePower === void 0 ? void 0 : electricEnginePower.toString()) || null,
        },
        {
            id: 'co2EmissionWltp',
            value: getCo2WLTPEmissionForEngineType(emissions, engineConcept),
        },
        {
            id: 'maxWeight',
            value: (_c = (_b = (_a = body === null || body === void 0 ? void 0 : body.weights) === null || _a === void 0 ? void 0 : _a.weightMax) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.toString(),
        },
        {
            id: 'seats',
            value: (_d = body === null || body === void 0 ? void 0 : body.seats) === null || _d === void 0 ? void 0 : _d.toString(),
        },
        {
            id: 'electricRangeWltp',
            value: (_j = (_h = (_g = (_f = (_e = engine === null || engine === void 0 ? void 0 : engine.electricEngine) === null || _e === void 0 ? void 0 : _e.wltpRange) === null || _f === void 0 ? void 0 : _f.total) === null || _g === void 0 ? void 0 : _g.all) === null || _h === void 0 ? void 0 : _h.individual) === null || _j === void 0 ? void 0 : _j.toString(),
        },
        {
            id: 'electricConsumptionWltp',
            value: getElectricConsumptionWltp(consumption === null || consumption === void 0 ? void 0 : consumption.wltp),
        },
    ].filter(({ value }) => value);
}
function getAdditionalValues(environmentalDiscount) {
    if (!environmentalDiscount) {
        return;
    }
    return [
        {
            rawAmount: environmentalDiscount,
            type: utils_2.ENVIRONMENTAL_BONUS_MANUFACTURER,
        },
    ];
}
function formatFuelType(fuelType = '') {
    return fuelType.replace('_', ' ').toLowerCase();
}
function getMainFuelType(fuelTypes) {
    return fuelTypes.length ? fuelTypes[0] : '';
}
function capitalize(value) {
    return typeof value === 'string' ? `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}` : '';
}
function getDefaultImage(vehicle) {
    return vehicle.stagedImgURL;
}
const getDefaultMotorization = (vehicles, typeId) => {
    return vehicles.find((item) => item.typeId === typeId) || vehicles[0];
};
exports.getDefaultMotorization = getDefaultMotorization;
const getDefaultBodytype = (vehicleClass, bodytypeId) => {
    const bodytypes = vehicleClass.bodies;
    const defaultBodytype = bodytypes.find((item) => {
        return item.id === bodytypeId;
    });
    return defaultBodytype || bodytypes[0];
};
exports.getDefaultBodytype = getDefaultBodytype;
