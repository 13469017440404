"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
const vuex_1 = require("vuex");
let AemFormWrapper = class AemFormWrapper extends vue_property_decorator_1.Vue {
};
AemFormWrapper = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'AemFormWrapper',
        computed: Object.assign({}, (0, vuex_1.mapGetters)(['market', 'locale', 'contactFormItems', 'aemFormId'])),
    })
], AemFormWrapper);
exports.default = AemFormWrapper;
