"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const utils_1 = require("@/utils");
const BASE_URL = {
    test: 'https://test.api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
    production: 'https://api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
    development: 'https://dev.api.oneweb.mercedes-benz.com/fcis-calculation-api/v1',
};
class FcisCalculationApi {
    constructor() {
        this.market = '';
        this.language = '';
        this.baseUrl = '';
    }
    setConfig(market, language, environment) {
        this.market = market;
        this.language = language;
        const base = BASE_URL[environment] || BASE_URL['production'];
        this.baseUrl = `${base}`;
    }
    fetchVehicleCosts(combinedElectricConsumption, combinedGasConsumption, combinedCo2Emission, engineCapacity, fuelType, engineConcept) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const urlPrefix = `${this.baseUrl}/vehicle/costs/envkv/${this.market}/${this.language}?`;
            let extraConsumptionParams = '';
            if ((0, utils_1.isElectricCCS)(engineConcept)) {
                // Electric vehicle scenario
                extraConsumptionParams = `combinedElectricConsumption=${combinedElectricConsumption}`;
            }
            else if ((0, utils_1.isHybridCCS)(engineConcept)) {
                // Hybrid vehicle scenario
                extraConsumptionParams = `combinedGasConsumption=${combinedGasConsumption}&combinedElectricConsumption=${combinedElectricConsumption}&engineCapacity=${engineCapacity}`;
            }
            else {
                // Combustion engine scenario
                extraConsumptionParams = `combinedGasConsumption=${combinedGasConsumption}&engineCapacity=${engineCapacity}`;
            }
            const url = `${urlPrefix}${extraConsumptionParams}&combinedCo2Emission=${combinedCo2Emission}&fuelType=${fuelType}&engineConcept=${engineConcept}`;
            const response = yield fetch(url);
            const result = yield response.json();
            return result;
        });
    }
    getVehicleList(config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const category = config.category;
            const applicationType = config.applicationType;
            const engineConcept = config.engineConcept;
            const queryParams = {
                modelSeries: config.modelSeries || null,
                subBrand: config.subBrand || null,
                productGroups: config.productGroups.join(','),
            };
            // remove queryparams null
            Object.keys(queryParams).forEach((key) => !queryParams[key] && delete queryParams[key]);
            const queryParamsString = new URLSearchParams(queryParams).toString();
            const url = `${this.baseUrl}/vehicle-list/${category}/${this.market}/${this.language}?${queryParamsString}`;
            const response = yield fetch(url);
            const result = yield response.json();
            if (applicationType === 'single') {
                if (((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.modelSeriesVehicles) === null || _b === void 0 ? void 0 : _b.vehicles) && engineConcept) {
                    result.data.modelSeriesVehicles.vehicles = result.data.modelSeriesVehicles.vehicles.filter(({ technicalData: { engine } }) => engine.engineConcept === engineConcept);
                }
                return ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.modelSeriesVehicles) || [];
            }
            return ((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.classBodytypeVehicles) || [];
        });
    }
}
const fcisCalculationApi = new FcisCalculationApi();
exports.default = fcisCalculationApi;
