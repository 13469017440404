"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GROSS_LIST_PRICE = exports.BPM_TAX = exports.STEPS = void 0;
var STEPS;
(function (STEPS) {
    STEPS["ONE_CHOOSE_CLASS"] = "ONE_CHOOSE_CLASS";
    STEPS["TWO_CHOOSE_BODY_TYPE"] = "TWO_CHOOSE_BODY_TYPE";
    STEPS["THREE_CHOOSE_MOTORIZATION"] = "THREE_CHOOSE_MOTORIZATION";
})(STEPS || (exports.STEPS = STEPS = {}));
exports.BPM_TAX = 'BPM';
exports.GROSS_LIST_PRICE = 'grossListPrice';
