"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorTitleStep = class VehicleSelectorTitleStep extends vue_property_decorator_1.Vue {
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorTitleStep.prototype, "title", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: false })
], VehicleSelectorTitleStep.prototype, "vehicleImg", void 0);
VehicleSelectorTitleStep = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorTitleStep' })
], VehicleSelectorTitleStep);
exports.default = VehicleSelectorTitleStep;
