"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchEmissionsAndConsumptionForVehicle = exports.fetchSingleModel = exports.fetchMultipleClasses = exports.validOcapiTaxesTypes = exports.VehicleCategory = void 0;
const tslib_1 = require("tslib");
const graphql_1 = require("@/graphql");
const logger_1 = require("@/utils/logger");
const types_1 = require("@/store/modules/vmds/types");
const utils_1 = require("@/utils");
var VehicleCategory;
(function (VehicleCategory) {
    VehicleCategory["COMMERCIAL"] = "COMMERCIAL";
    VehicleCategory["PRIVATE"] = "PRIVATE";
})(VehicleCategory || (exports.VehicleCategory = VehicleCategory = {}));
exports.validOcapiTaxesTypes = [
    'acquisitionTax',
    'automobileTax',
    'bpm',
    'carAssessmentFee',
    'compulsoryInsurance',
    'costExpenses',
    'delivery',
    'deliveryFee',
    'ecv',
    'frf',
    'garageFee',
    'garageHandlingFee',
    'inspectionFee',
    'inspectionHandlingFee',
    'ipt',
    'isv',
    'licensePlateFee',
    'maintenancePackageFee',
    'notarialFee',
    'nova',
    'otherNonTaxableFee',
    'preparationFee',
    'recycleDeposit',
    'recyclingFee',
    'registrationTax',
    'stampDutyFee',
    'vat',
    'ved',
    'vrp',
    'warrantFee',
    'weightTax',
];
/**
 * Fetch bodytypes belonging to class id
 * @param {any} options
 * @returns {Array<any>}
 */
const fetchMultipleClasses = (options) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    const { market, language, uri = '', subBrands = null, brand } = options;
    try {
        const apolloClient = (0, graphql_1.useApolloClient)(uri);
        const isVans = brand === utils_1.APP_BRAND.VANS;
        const vehiclesCategory = isVans ? VehicleCategory.COMMERCIAL : VehicleCategory.PRIVATE;
        const productGroups = isVans ? [types_1.ProductGroup.VAN] : [types_1.ProductGroup.PASSENGER_CAR, types_1.ProductGroup.VAN];
        const { data } = yield apolloClient.query({
            query: graphql_1.multipleClassQuery,
            variables: {
                market,
                language,
                filter: {
                    subBrands,
                    lifeCycles: 'FOR_SALE',
                    vehiclesCategory,
                    productGroups,
                },
            },
        });
        return (data === null || data === void 0 ? void 0 : data.classBodytypeVehicles) || [];
    }
    catch (e) {
        logger_1.logger.error(e);
        return [];
    }
});
exports.fetchMultipleClasses = fetchMultipleClasses;
/**
 * Fetch vehicles belonging to modelSeries
 * @param {IModelSeriesFetchOptions} options
 * @returns {Array<any>}
 */
const fetchSingleModel = (options) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { market, language, uri = '', modelSeries = '', subBrands = null, engineConcept = null, brand } = options;
    try {
        const apolloClient = (0, graphql_1.useApolloClient)(uri);
        const isVans = brand === utils_1.APP_BRAND.VANS;
        const vehiclesCategory = !isVans ? VehicleCategory.PRIVATE : null;
        const productGroups = isVans ? [types_1.ProductGroup.VAN] : [types_1.ProductGroup.PASSENGER_CAR, types_1.ProductGroup.VAN];
        const { data } = yield apolloClient.query({
            query: graphql_1.singleClassQuery,
            variables: {
                market,
                language,
                modelSeries,
                filter: {
                    lifeCycles: ['FOR_SALE'],
                    productGroups,
                    subBrands,
                    vehiclesCategory,
                },
            },
        });
        if (((_a = data === null || data === void 0 ? void 0 : data.modelSeriesVehicles) === null || _a === void 0 ? void 0 : _a.vehicles) && engineConcept) {
            data.modelSeriesVehicles.vehicles = data.modelSeriesVehicles.vehicles.filter(({ technicalData: { engine } }) => engine.engineConcept === engineConcept);
        }
        return (data === null || data === void 0 ? void 0 : data.modelSeriesVehicles) || [];
    }
    catch (e) {
        logger_1.logger.error(e);
        return [];
    }
});
exports.fetchSingleModel = fetchSingleModel;
/**
 * Fetch emission/consumption data for a specific vehicle
 * @param {IEmissionFetchOptions} options
 * @returns {Array<any>}
 */
const fetchEmissionsAndConsumptionForVehicle = (options) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { market, language, typeId, uri = '' } = options;
    try {
        const apolloClient = (0, graphql_1.useApolloClient)(uri);
        const { data } = yield apolloClient.query({
            query: graphql_1.singleVehicleEmissionConsumptionQuery,
            variables: {
                market,
                language,
                typeId,
            },
        });
        return ((_a = data === null || data === void 0 ? void 0 : data.typeIdVehicle) === null || _a === void 0 ? void 0 : _a.technicalData) || {};
    }
    catch (e) {
        logger_1.logger.error(e);
        return {};
    }
});
exports.fetchEmissionsAndConsumptionForVehicle = fetchEmissionsAndConsumptionForVehicle;
