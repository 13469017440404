"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERROR = void 0;
exports.ERROR = {
    MARKET_UNAVAILABLE: "The Calculator isn't available for the combination of locale/market.",
    VMDS_INVALID_CLASS: "[VMDS] - The selected Class isn't available.",
    VMDS_INVALID_BODYTYPE: "[VMDS] - The selected Bodytype isn't available.",
    ONECALC_UNAVAILABLE: '[OCAPI] - The API is not available.',
    ONECALC_ERROR: "[OCAPI] - The API couldn't fulfil the calculation.",
    NO_OUTPUT_CONTAINERS: 'The Calculator is unable to provide a valid quote.',
    DEFAULT: 'The Calculator is unable to provide a valid quote.',
};
