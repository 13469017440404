"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorModel = class VehicleSelectorModel extends vue_property_decorator_1.Vue {
    onClick() {
        this.$emit('click');
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorModel.prototype, "id", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorModel.prototype, "name", void 0);
VehicleSelectorModel = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorModel' })
], VehicleSelectorModel);
exports.default = VehicleSelectorModel;
