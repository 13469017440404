"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
const vuex_1 = require("vuex");
const utils_1 = require("@/components/consumption/utils");
let ConsumptionComponent = class ConsumptionComponent extends vue_property_decorator_1.Vue {
    getValueForItem(item) {
        return ((0, utils_1.getValue)(item, {
            emissions: this.currentEmissions,
            consumption: this.currentConsumption,
            engine: this.currentEngine,
        }) || '');
    }
    get entries() {
        return this.wltpItems.filter((item) => {
            return item.engines.includes(this.currentEngineConcept);
        });
    }
};
ConsumptionComponent = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'ConsumptionComponent',
        computed: Object.assign({}, (0, vuex_1.mapGetters)([
            'wltpItems',
            'currentEngine',
            'currentEmissions',
            'currentConsumption',
            'currentEngineConcept',
            'translate',
        ])),
    })
], ConsumptionComponent);
exports.default = ConsumptionComponent;
