"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let Modal = class Modal extends vue_property_decorator_1.Vue {
    mounted() {
        this.modalListenerOnClose = this.$el.addEventListener('wbclose', (event) => {
            this.$emit('close', event);
        }, false);
    }
    beforeDestroy() {
        this.$el.removeEventListener('wbclose', this.modalListenerOnClose, false);
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'info' })
], Modal.prototype, "mode", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: ' ' })
], Modal.prototype, "title", void 0);
Modal = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'Modal' })
], Modal);
exports.default = Modal;
