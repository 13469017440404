"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
class Logger {
    constructor() {
        this._infoColors = ['#46adef', '#d182db'];
        this._emojis = ['💡', '💰', '💸', '😀', '😁', '🥳', '🎉'];
    }
    log(params, forceLog) {
        if (process.env.NODE_ENV !== 'production' || forceLog) {
            console.log(...params);
        }
    }
    /**
     * Log Info!
     * @param value{string} - Phrase or Value to Log
     */
    info(value) {
        const colors = this._infoColors.filter((color) => color !== this._lastInfoColor);
        this._lastInfoColor = colors[Math.floor(Math.random() * colors.length)];
        const emojis = this._emojis.filter((color) => color !== this._lastEmoji);
        this._lastEmoji = Math.floor(Math.random() * 2) ? emojis[Math.floor(Math.random() * colors.length)] : '';
        this.log([
            '%c%s',
            `color: white; background: ${this._lastInfoColor}; font-size: 11px; padding: 5px`,
            `${value.trim()} ${this._lastEmoji}`.trim(),
        ]);
    }
    /**
     * Log Warnings!
     * @param value{string} - Phrase or Value to Log
     */
    warn(value) {
        this.log(['%c%s', 'color: white; background: #dbc479; font-size: 11px; padding: 5px', `${value}`]);
    }
    /**
     * Log Errors!
     * @param value{string} - Phrase or Value to Log
     */
    error(value) {
        this.log(['%c%s', 'color: white; background: #db7485; font-size: 11px; padding: 5px', `${value} ⛔`], true);
    }
}
exports.logger = new Logger();
