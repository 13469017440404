"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const labels_1 = require("@/utils/labels");
const logger_1 = require("@/utils/logger");
const vue_property_decorator_1 = require("vue-property-decorator");
let Error = class Error extends vue_property_decorator_1.Vue {
    /**
     * Computed properties
     */
    get errorMessage() {
        return labels_1.ERROR[this.code] || labels_1.ERROR['DEFAULT'];
    }
    /**
     * Lifecycle methods
     */
    mounted() {
        logger_1.logger.error(labels_1.ERROR[this.code] || labels_1.ERROR['DEFAULT']);
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], Error.prototype, "code", void 0);
Error = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'Error' })
], Error);
exports.default = Error;
