"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHARGES = exports.MAYBACH_SUBBRAND = void 0;
exports.mapTaxDetails = mapTaxDetails;
exports.getPriceCharges = getPriceCharges;
exports.sortClasses = sortClasses;
exports.filterClassesByBlackList = filterClassesByBlackList;
const utils_1 = require("@/utils");
exports.MAYBACH_SUBBRAND = utils_1.SubBrand.MERCEDES_MAYBACH;
exports.CHARGES = {
    TK: 'DELIVERY',
    DF: 'DELIVERY',
    'TK-VAT': 'DELIVERY',
    DELIVERYCOST: 'DELIVERY',
};
/**
 * mapTaxDetails
 * @param taxes A list of taxes
 * @returns - A key-value object with all the charges already mapped
 */
function mapTaxDetails(taxes) {
    const map = {};
    if (!Array.isArray(taxes)) {
        return map;
    }
    taxes.forEach((tax) => {
        const id = (exports.CHARGES[tax.name] || tax.name).toLowerCase();
        map[tax.name] = { label: id, type: id, rawAmount: Number(tax.amount), rawRate: tax.rate };
    });
    return map;
}
/**
 * getPriceCharges: Return a list of charges of a given vehicle price
 * e.g.: grossListPrice
 */
function getPriceCharges(chargesMap) {
    return Object.values(chargesMap);
}
function sortClasses(a, b) {
    const nameA = a.className.toUpperCase();
    const nameB = b.className.toUpperCase();
    if (nameA < nameB) {
        // Sort A before B
        return -1;
    }
    if (nameA > nameB) {
        // Sort B before A
        return 1;
    }
    // Does nothing
    return 0;
}
function filterClassesByBlackList(items, blacklist) {
    return items.filter((item) => !blacklist.includes(item.classId));
}
