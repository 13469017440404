"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorSlides = class VehicleSelectorSlides extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.onSliding = 'fcis-vehicle-selector-slides--on-sliding';
    }
    beforeEnter() {
        this.$el.classList.add(this.onSliding);
    }
    afterLeave() {
        this.$el.classList.remove(this.onSliding);
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'left' })
], VehicleSelectorSlides.prototype, "direction", void 0);
VehicleSelectorSlides = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorSlides' })
], VehicleSelectorSlides);
exports.default = VehicleSelectorSlides;
