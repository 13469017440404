"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutputsModule = exports.getters = exports.actions = exports.mutations = exports.defaultState = void 0;
const tslib_1 = require("tslib");
const utils_1 = require("@/utils");
const DeeplinkService_1 = tslib_1.__importStar(require("@/services/DeeplinkService"));
const TrackingManager_1 = require("@/utils/TrackingManager");
const sortButtonsByClassification = (a, b) => {
    if (a.classification === b.classification) {
        return 0;
    }
    else if (a.classification === utils_1.BUTTON_TYPE.CALL_TO_ACTION && b.classification === utils_1.BUTTON_TYPE.DEFAULT) {
        return 1;
    }
    return -1;
};
exports.defaultState = {
    title: '',
    quoteData: [],
    disclaimers: [],
    buttons: [],
    deeplink: {
        items: [],
        typeId: '',
    },
    contactFormItems: {},
    legalBannerImageUrl: '',
    legalBannerText: '',
    cleoCustomerTypeInput: null,
    displayCustomerTypeToggle: false,
    customerTypeToggleValue: '',
};
exports.mutations = {
    SET_TITLE: (state, title) => {
        state.title = title;
    },
    SET_QUOTE_DATA: (state, data) => {
        state.quoteData = [...data];
    },
    SET_BUTTONS: (state, data) => {
        state.buttons = [...data];
    },
    SET_DEEPLINK_ITEMS: (state, data) => {
        state.deeplink.items = [...data];
    },
    SET_DEEPLINK_TYPE_ID: (state, typeId) => {
        state.deeplink.typeId = typeId;
    },
    SET_LEGAL_BANNER_IMAGE: (state, data) => {
        state.legalBannerImageUrl = data;
    },
    SET_LEGAL_BANNER_TEXT: (state, data) => {
        state.legalBannerText = data;
    },
    SET_DISCLAIMERS_DATA: (state, data) => {
        state.disclaimers = data;
    },
    SET_CONTACT_FORM_DATA: (state, payload) => {
        state.contactFormItems = payload;
    },
    SET_CUSTOMER_TYPE_INPUT: (state, payload) => {
        var _a, _b, _c;
        const oldCustomerType = (_b = (_a = state.cleoCustomerTypeInput) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.value;
        const newCustomerType = (_c = payload === null || payload === void 0 ? void 0 : payload.value) === null || _c === void 0 ? void 0 : _c.value;
        const trackingName = (newCustomerType !== null && newCustomerType !== void 0 ? newCustomerType : '').trim().replaceAll(' ', '_');
        if (oldCustomerType && newCustomerType !== oldCustomerType) {
            TrackingManager_1.tracking.update(`calculation_customer_type.${trackingName}`);
        }
        state.cleoCustomerTypeInput = payload;
    },
    SET_DISPLAY_CUSTOMER_TYPE_TOGGLE: (state, payload) => {
        state.displayCustomerTypeToggle = payload;
    },
    SET_CUSTOMER_TYPE_CHANGE: (state, payload) => {
        state.customerTypeToggleValue = payload;
    },
};
exports.actions = {
    setDisclaimers({ commit }, payload) {
        commit('SET_DISCLAIMERS_DATA', payload);
    },
    setTitle({ commit }, title) {
        commit('SET_TITLE', title);
    },
    setDeeplinkItems(_a, typeId_1) {
        return tslib_1.__awaiter(this, arguments, void 0, function* ({ commit, state }, typeId) {
            if (typeId !== state.deeplink.typeId) {
                const result = yield DeeplinkService_1.default.fetchLinkForTypeId(typeId);
                commit('SET_DEEPLINK_ITEMS', result);
                commit('SET_DEEPLINK_TYPE_ID', typeId);
            }
        });
    },
    setLegalBannerImage({ commit }, url) {
        commit('SET_LEGAL_BANNER_IMAGE', url);
    },
    setLegalBannerText({ commit }, text) {
        commit('SET_LEGAL_BANNER_TEXT', text);
    },
    setButtons(_a, ocapiButtons_1) {
        return tslib_1.__awaiter(this, arguments, void 0, function* ({ dispatch, commit, rootGetters, state }, ocapiButtons) {
            yield dispatch('setDeeplinkItems', rootGetters.currentVehicle.typeId);
            const deepLinks = state.deeplink.items || [];
            /* remove black listed buttons  */
            const removeBlackListedButtons = (button) => {
                var _a;
                return !((_a = rootGetters.configurations.outputs.buttonsBlackList) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === button.id && item.class === rootGetters.currentVehicle.classId));
            };
            const deepLinksFiltered = deepLinks === null || deepLinks === void 0 ? void 0 : deepLinks.filter((button) => {
                return removeBlackListedButtons(button);
            });
            // Links without support from deepLinks service
            // Also, filter out buttons without IDs as they cannot be validated
            const unsuportedLinks = ocapiButtons
                .filter((item) => item.id)
                .filter((item) => !DeeplinkService_1.supportedButtons.includes(item.id));
            // Links supported by deepLinks service
            const supportedLinks = ocapiButtons.filter((item) => DeeplinkService_1.supportedButtons.includes(item.id));
            // Link with results from deepLink service
            const deeplinkItems = supportedLinks
                .map((button) => {
                const deeplink = deepLinksFiltered.find((link) => link.id === button.id);
                return Object.assign({ classification: button.classification }, deeplink);
            })
                .filter((item) => item.url);
            const configurationButtons = rootGetters.configurations.outputs.buttons || [];
            const translatedButtons = configurationButtons.map((button) => {
                return Object.assign(Object.assign({}, button), { label: rootGetters.translate(button.label) });
            });
            const allButtons = [...translatedButtons, ...deeplinkItems, ...unsuportedLinks];
            allButtons.sort(sortButtonsByClassification);
            commit('SET_BUTTONS', allButtons);
        });
    },
    setQuoteData({ commit }, payload) {
        commit('SET_QUOTE_DATA', payload);
    },
    setContactFormItems({ commit }, payload) {
        commit('SET_CONTACT_FORM_DATA', Object.assign(Object.assign({}, exports.defaultState.contactFormItems), payload));
    },
    setCustomerTypeInput({ commit }, payload) {
        commit('SET_CUSTOMER_TYPE_INPUT', payload);
    },
    shouldDisplayCustomerTypeToggle({ commit }, payload) {
        commit('SET_DISPLAY_CUSTOMER_TYPE_TOGGLE', payload);
    },
    setCustomerTypeChange({ commit }, payload) {
        commit('SET_CUSTOMER_TYPE_CHANGE', payload);
    },
};
exports.getters = {
    title: (state) => state.title,
    quoteData: (state) => state.quoteData,
    buttons: (state) => state.buttons,
    disclaimers: (state) => state.disclaimers,
    contactFormItems: (state) => state.contactFormItems,
    legalBannerImageUrl: (state) => state.legalBannerImageUrl,
    legalBannerText: (state) => state.legalBannerText,
    cleoCustomerTypeInput: (state) => state.cleoCustomerTypeInput,
    displayCustomerTypeToggle: (state) => state.displayCustomerTypeToggle,
    customerTypeToggleValue: (state) => state.customerTypeToggleValue,
};
exports.OutputsModule = {
    state: exports.defaultState,
    mutations: exports.mutations,
    actions: exports.actions,
    getters: exports.getters,
};
