"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EngineConceptCCS = exports.EngineConcept = exports.Transmission = exports.ProductGroup = exports.EnergyEfficiencyClass = exports.FuelTypeCCS = exports.FuelType = void 0;
var FuelType;
(function (FuelType) {
    FuelType["DIESEL"] = "DIESEL";
    FuelType["ELECTRIC"] = "ELECTRIC";
    FuelType["ETHANOL"] = "ETHANOL";
    FuelType["HYDROGEN"] = "HYDROGEN";
    FuelType["PETROL"] = "PETROL";
    FuelType["PETROL_PLUS"] = "PETROL_PLUS";
})(FuelType || (exports.FuelType = FuelType = {}));
var FuelTypeCCS;
(function (FuelTypeCCS) {
    FuelTypeCCS["DIESEL"] = "DIESEL";
    FuelTypeCCS["ELECTRIC"] = "ELECTRIC";
    FuelTypeCCS["SUPER"] = "SUPER";
    FuelTypeCCS["SUPERPLUS"] = "SUPERPLUS";
    FuelTypeCCS["SUPER_E10"] = "ETHANOL";
})(FuelTypeCCS || (exports.FuelTypeCCS = FuelTypeCCS = {}));
var EnergyEfficiencyClass;
(function (EnergyEfficiencyClass) {
    EnergyEfficiencyClass["A_TRIPLE_PLUS"] = "A_TRIPLE_PLUS";
    EnergyEfficiencyClass["A_DOUBLE_PLUS"] = "A_DOUBLE_PLUS";
    EnergyEfficiencyClass["A_PLUS"] = "A_PLUS";
    EnergyEfficiencyClass["A"] = "A";
    EnergyEfficiencyClass["B"] = "B";
    EnergyEfficiencyClass["C"] = "C";
    EnergyEfficiencyClass["D"] = "D";
    EnergyEfficiencyClass["E"] = "E";
    EnergyEfficiencyClass["F"] = "F";
    EnergyEfficiencyClass["G"] = "G";
})(EnergyEfficiencyClass || (exports.EnergyEfficiencyClass = EnergyEfficiencyClass = {}));
var ProductGroup;
(function (ProductGroup) {
    ProductGroup["PASSENGER_CAR"] = "PASSENGER_CAR";
    ProductGroup["SMART"] = "SMART";
    ProductGroup["VAN"] = "VAN";
})(ProductGroup || (exports.ProductGroup = ProductGroup = {}));
var Transmission;
(function (Transmission) {
    Transmission["AUTOMATIC"] = "AUTOMATIC";
    Transmission["MANUAL"] = "MANUAL";
})(Transmission || (exports.Transmission = Transmission = {}));
var EngineConcept;
(function (EngineConcept) {
    EngineConcept["COMBUSTION"] = "COMBUSTION";
    EngineConcept["ELECTRIC"] = "ELECTRIC";
    EngineConcept["FUEL_CELL"] = "FUEL_CELL";
    EngineConcept["MILD_HYBRID"] = "MILD_HYBRID";
    EngineConcept["PLUGIN_HYBRID"] = "PLUGIN_HYBRID";
})(EngineConcept || (exports.EngineConcept = EngineConcept = {}));
var EngineConceptCCS;
(function (EngineConceptCCS) {
    EngineConceptCCS["COMBUSTOR"] = "COMBUSTOR";
    EngineConceptCCS["ELECTRIC"] = "ELECTRIC";
    EngineConceptCCS["HYBRID"] = "HYBRID";
})(EngineConceptCCS || (exports.EngineConceptCCS = EngineConceptCCS = {}));
