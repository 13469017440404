"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportedButtons = void 0;
const tslib_1 = require("tslib");
const utils_1 = require("@/utils");
const BASE_URL = {
    test: 'https://int.api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
    production: 'https://api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
    development: 'https://test.api.oneweb.mercedes-benz.com/vehicle-deeplinks-api/v1/deeplinks',
};
const X_API_KEYS = {
    test: 'a477877d-7b46-4ec6-961e-6a51d7ba0bf7',
    production: '8a6eb7ed-5833-40a6-a081-38deaf0bf8a7',
    development: '8006041d-5b3e-4774-9cfc-4a68b3462b13',
};
exports.supportedButtons = [
    utils_1.OCAPI_BUTTON_TYPE.TEST_DRIVE,
    utils_1.OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR,
    utils_1.OCAPI_BUTTON_TYPE.SHOWROOM_STOCK,
];
class DeeplinkService {
    constructor() {
        this.market = '';
        this.language = '';
        this.baseUrl = '';
        this.X_API_KEY = '';
    }
    setMarketAndLanguage(market, language, environment) {
        this.market = market;
        this.language = language;
        this.X_API_KEY = X_API_KEYS[environment] || X_API_KEYS['production'];
        const base = BASE_URL[environment] || BASE_URL['production'];
        this.baseUrl = `${base}/${this.market}/${this.language}`;
    }
    translateKey(key) {
        switch (key) {
            case 'CONFIGURATOR':
                return utils_1.OCAPI_BUTTON_TYPE.CAR_CONFIGURATOR;
            case 'ONLINE_SHOP':
                return utils_1.OCAPI_BUTTON_TYPE.SHOWROOM_STOCK;
            case 'TEST_DRIVE':
                return utils_1.OCAPI_BUTTON_TYPE.TEST_DRIVE;
            default:
                return key;
        }
    }
    fetchLinkForTypeId(typeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${this.baseUrl}/type-id/${typeId}`;
            const response = yield fetch(url, {
                headers: {
                    'x-api-key': this.X_API_KEY,
                },
            });
            const result = yield response.json();
            return Object.keys(result).map((key) => (Object.assign(Object.assign({}, result[key]), { id: this.translateKey(key) })));
        });
    }
}
const deeplinkService = new DeeplinkService();
exports.default = deeplinkService;
