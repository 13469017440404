"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let LoadingSpinner = class LoadingSpinner extends vue_property_decorator_1.Vue {
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 50 })
], LoadingSpinner.prototype, "width", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 50 })
], LoadingSpinner.prototype, "height", void 0);
LoadingSpinner = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'LoadingSpinner' })
], LoadingSpinner);
exports.default = LoadingSpinner;
