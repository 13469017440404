"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vuex_1 = require("vuex");
const vue_property_decorator_1 = require("vue-property-decorator");
const TrackingManager_1 = require("@/utils/TrackingManager");
let LegalBanner = class LegalBanner extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.isOpen = true;
    }
    handleClick() {
        if (this.isOpen) {
            TrackingManager_1.tracking.action('dismiss', 'feature', 'legal_disclaimer_banner');
        }
        this.isOpen = !this.isOpen;
    }
};
LegalBanner = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'LegalBanner',
        computed: Object.assign({}, (0, vuex_1.mapGetters)(['legalBannerImageUrl', 'legalBannerText'])),
    })
], LegalBanner);
exports.default = LegalBanner;
