"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorBodyType = class VehicleSelectorBodyType extends vue_property_decorator_1.Vue {
    onClick() {
        this.$emit('click');
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorBodyType.prototype, "id", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorBodyType.prototype, "name", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorBodyType.prototype, "imageSrc", void 0);
VehicleSelectorBodyType = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorBodyType' })
], VehicleSelectorBodyType);
exports.default = VehicleSelectorBodyType;
