"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
const utils_1 = require("@/store/modules/vehicleSelector/utils");
const vuex_1 = require("vuex");
const utils_2 = require("@/utils");
let VehicleDetail = class VehicleDetail extends vue_property_decorator_1.Vue {
    get formattedTransmission() {
        return (0, utils_1.capitalize)(this.transmission);
    }
    get formattedFuelType() {
        const fuelTypeFormatted = (0, utils_1.capitalize)(this.fuelType);
        if ((0, utils_2.isHybrid)(this.engineConcept)) {
            return `${this.hybridTranslation} (${fuelTypeFormatted}) `;
        }
        return fuelTypeFormatted;
    }
    get connectorWordTranslation() {
        if (this.market !== 'DE') {
            return '';
        }
        return this.translate('upTo');
    }
    get enginePower() {
        const [firstEngine, secondEngine] = this.power;
        const firstEnginePower = firstEngine ? `${firstEngine.value} ${firstEngine.unit}` : '';
        if (secondEngine) {
            const secondEnginePower = `${secondEngine.value} ${secondEngine.unit}`;
            return `${firstEnginePower} + ${this.connectorWordTranslation} ${secondEnginePower}`;
        }
        return firstEnginePower;
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "fuelType", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "power", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "transmission", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "fuelTypeLabel", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "enginePowerLabel", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "transmissionLabel", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "engineConcept", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleDetail.prototype, "hybridTranslation", void 0);
VehicleDetail = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'VehicleDetail',
        computed: Object.assign({}, (0, vuex_1.mapGetters)(['translate', 'market'])),
    })
], VehicleDetail);
exports.default = VehicleDetail;
