"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
const vuex_1 = require("vuex");
let Toggle = class Toggle extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.selectedInput = '';
        this.shouldBeAbleToChangeCustomer = false;
    }
    mounted() {
        this.shouldBeAbleToChangeCustomer = this.cleoCustomerTypeInput.values.length > 1;
    }
    handleChange(e) {
        this.shouldBeAbleToChangeCustomer = this.cleoCustomerTypeInput.values.length > 1;
        const isOcapiSecondValueSelected = this.shouldBeAbleToChangeCustomer && this.cleoCustomerTypeInput.values[1].selected;
        this.selectedInput = isOcapiSecondValueSelected
            ? this.cleoCustomerTypeInput.values[0].value
            : this.cleoCustomerTypeInput.values[1].value;
        this.setCustomerTypeChange(this.selectedInput);
        e.stopPropagation();
    }
};
Toggle = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'Toggle',
        computed: Object.assign({}, (0, vuex_1.mapGetters)(['cleoCustomerTypeInput'])),
        methods: Object.assign({}, (0, vuex_1.mapActions)(['setCustomerTypeChange'])),
    })
], Toggle);
exports.default = Toggle;
