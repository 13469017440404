"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlsValue = exports.getValue = exports.valueForKey = void 0;
const utils_1 = require("@/utils");
const valueForKey = (dataObject, key) => {
    if (!key) {
        return;
    }
    const value = (0, utils_1.getDescendantProp)(key, dataObject);
    return value === null || value === void 0 ? void 0 : value.toString();
};
exports.valueForKey = valueForKey;
const getValue = (item, dataObject) => {
    const primaryValue = (0, exports.valueForKey)(dataObject, item.valueKey);
    const unit = item.unitKey ? (0, exports.valueForKey)(dataObject, item.unitKey) : '';
    const secondaryValue = item.secondaryValueKey && (0, exports.valueForKey)(dataObject, item.secondaryValueKey);
    const { separator = '' } = item;
    if (!primaryValue || (secondaryValue && !separator) || primaryValue === '[object Object]') {
        return '-';
    }
    // Only display secondary value if defined and different from primary
    if (secondaryValue && primaryValue !== secondaryValue) {
        return `${primaryValue} ${separator} ${secondaryValue} ${unit}`.trim();
    }
    return `${primaryValue} ${unit}`.trim();
};
exports.getValue = getValue;
const getPlsValue = (key, dataObject) => {
    const primaryValue = (0, exports.valueForKey)(dataObject, key);
    if (!primaryValue || primaryValue === '[object Object]') {
        return 0;
    }
    return parseFloat(primaryValue);
};
exports.getPlsValue = getPlsValue;
