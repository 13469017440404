"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
const vuex_1 = require("vuex");
const utils_1 = require("@/store/modules/vehicleSelector/utils");
const utils_2 = require("@/utils");
const types_1 = require("@/store/modules/vmds/types");
const utils_3 = require("@/components/consumption/utils");
let PlsPriceUi = class PlsPriceUi extends vue_property_decorator_1.Vue {
    get configProduct() {
        var _a;
        return {
            productType: 'CONFIGURATION',
            vehicleId: (_a = this.vehicle) === null || _a === void 0 ? void 0 : _a.ccsId,
            productGroup: this.vehicle.productGroup === 'VAN' ? 'VAN' : 'PC',
        };
    }
    get plsLocal() {
        return this.locale.replace('_', '-');
    }
    get plsFuelType() {
        if (this.isHybrid) {
            return 'HYBRID';
        }
        const mainFuelType = (0, utils_1.getMainFuelType)(this.vehicle.fuelTypes);
        switch (mainFuelType) {
            case types_1.FuelType.ELECTRIC:
                return 'ELECTRIC';
            default:
                return 'COMBUSTOR';
        }
    }
    get getPlsTechnicalData() {
        const mappings = {
            co2EmissionTotalCombined: 'emissions.automatic.wltp.weighted.primaryEngine.individual',
            fuelConsumptionTotalCombined: this.isHybrid
                ? 'consumption.automatic.wltp.weighted.primaryEngine.individual'
                : 'consumption.automatic.wltp.total.primaryEngine.individual',
            'engine.driveCycle.wltp.emission.total': 'emissions.automatic.wltp.total.primaryEngine.individual',
            'engine.driveCycle.wltp.emission.weighted': 'emissions.automatic.wltp.weighted.primaryEngine.individual',
            'engine.driveCycle.range.totalAll': 'engine.electricEngine.wltpRange.automatic.total.all.individual',
            'engine.driveCycle.range.totalEquivalentAll': 'engine.electricEngine.wltpRange.automatic.total.equivalentAll.individual',
        };
        const plsTechnicalData = Object.keys(mappings).reduce((aggregatedTechnicalData, key) => (Object.assign(Object.assign({}, aggregatedTechnicalData), { [key]: (0, utils_3.getPlsValue)(mappings[key], this.vehicle.technicalData) || 0 })), {});
        return plsTechnicalData;
    }
    get isHybrid() {
        return (0, utils_2.isHybrid)(this.vehicle.engineConcept);
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], PlsPriceUi.prototype, "vehicle", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ default: 'lazy' })
], PlsPriceUi.prototype, "loading", void 0);
PlsPriceUi = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({
        name: 'PlsPriceUi',
        computed: Object.assign({}, (0, vuex_1.mapGetters)(['locale'])),
    })
], PlsPriceUi);
exports.default = PlsPriceUi;
