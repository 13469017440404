"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let MQIndicator = class MQIndicator extends vue_property_decorator_1.Vue {
};
MQIndicator = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'MQIndicator' })
], MQIndicator);
exports.default = MQIndicator;
