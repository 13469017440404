"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleVehicleEmissionConsumptionQuery = exports.multipleClassQuery = exports.singleClassQuery = void 0;
exports.useApolloClient = useApolloClient;
const core_1 = require("@apollo/client/core");
const singleClassQuery_1 = require("@/graphql/singleClassQuery");
Object.defineProperty(exports, "singleClassQuery", { enumerable: true, get: function () { return singleClassQuery_1.singleClassQuery; } });
const multipleClassQuery_1 = require("@/graphql/multipleClassQuery");
Object.defineProperty(exports, "multipleClassQuery", { enumerable: true, get: function () { return multipleClassQuery_1.multipleClassQuery; } });
const singleVehicleEmissionConsumptionQuery_1 = require("@/graphql/singleVehicleEmissionConsumptionQuery");
Object.defineProperty(exports, "singleVehicleEmissionConsumptionQuery", { enumerable: true, get: function () { return singleVehicleEmissionConsumptionQuery_1.singleVehicleEmissionConsumptionQuery; } });
let apolloClient;
let clientUrl = '';
function useApolloClient(uri) {
    if (apolloClient && uri === clientUrl) {
        return apolloClient;
    }
    apolloClient = new core_1.ApolloClient({
        uri,
        cache: new core_1.InMemoryCache({
            addTypename: false,
        }),
    });
    clientUrl = uri;
    return apolloClient;
}
