"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAemEnvironmentVariables = void 0;
const store_1 = require("@seamless/store");
const connection_aem_environment_1 = require("@owc/connection-aem-environment");
const getAemEnvironmentVariables = () => new Promise((resolve) => {
    const store = (0, store_1.initializeStore)();
    (0, connection_aem_environment_1.addConnectionAemEnvironmentConnection)().finally(() => {
        store.once(connection_aem_environment_1.connectionName, (state) => {
            resolve(state);
        });
    });
});
exports.getAemEnvironmentVariables = getAemEnvironmentVariables;
