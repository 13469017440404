"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAemComponentData = getAemComponentData;
const tslib_1 = require("tslib");
const component_registry_connection_1 = require("@owc/component-registry-connection");
/**
 * Read AEM dialogs
 * @param componentId e.g. dh-io-fcis, fcis-owdc
 */
function getAemComponentData(componentId) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const store = (0, component_registry_connection_1.initializeComponentRegistry)();
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            const listener = (state) => {
                resolve(state);
                store.unsubscribe(componentId, listener);
            };
            store.subscribe(componentId, listener);
        });
    });
}
