"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorNavigation = class VehicleSelectorNavigation extends vue_property_decorator_1.Vue {
    onNavigateBack(event) {
        this.$emit('onNavigateBack', event);
    }
    onClose(event) {
        this.$emit('onClose', event);
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorNavigation.prototype, "isNavigateBackDisabled", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)({ required: true })
], VehicleSelectorNavigation.prototype, "navigateBackLabel", void 0);
VehicleSelectorNavigation = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorNavigation' })
], VehicleSelectorNavigation);
exports.default = VehicleSelectorNavigation;
