"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let GridColumn = class GridColumn extends vue_property_decorator_1.Vue {
    get classes() {
        let _classes = '';
        const offset = 'offset-mq';
        const prefix = 'wb-grid-col';
        const props = Object.keys(this.$props);
        props.forEach((prop) => {
            if (!this.$props[prop]) {
                return undefined;
            }
            const isOffsetMq = prop.indexOf('offsetMq') > -1;
            const mq = prop.charAt(prop.length - 1);
            // Concatenate a chain of classes. E.g.: wb-grid-col-mq1-10
            _classes += isOffsetMq
                ? `${prefix}-${offset}${mq}-${this.$props[prop]} `
                : `${prefix}-mq${mq}-${this.$props[prop]} `;
        });
        return _classes;
    }
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq1", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq1", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq2", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq2", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq3", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq3", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq4", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq4", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq5", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq5", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "mq6", void 0);
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], GridColumn.prototype, "offsetMq6", void 0);
GridColumn = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'GridColumn' })
], GridColumn);
exports.default = GridColumn;
