"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const vue_property_decorator_1 = require("vue-property-decorator");
let VehicleSelectorSlide = class VehicleSelectorSlide extends vue_property_decorator_1.Vue {
};
tslib_1.__decorate([
    (0, vue_property_decorator_1.Prop)()
], VehicleSelectorSlide.prototype, "title", void 0);
VehicleSelectorSlide = tslib_1.__decorate([
    (0, vue_property_decorator_1.Component)({ name: 'VehicleSelectorSlide' })
], VehicleSelectorSlide);
exports.default = VehicleSelectorSlide;
