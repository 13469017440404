"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plsMarkets = exports.avoidCarsWithNoPrice = exports.getValidPriceTag = exports.getCCSEngineConcept = exports.getCCSFuelType = exports.isElectricCCS = exports.isHybridCCS = exports.isHybrid = exports.isDemoMarket = exports.appendScript = exports.appendMeta = exports.smoothScrollTo = exports.INPUT_TYPES = exports.getUrlPrefix = exports.isValidValue = exports.getDescendantProp = exports.getInstallmentMonths = exports.ENGINE_TYPE = exports.ERROR = exports.getValidatedValueWithMinMax = exports.inputFormatting = exports.injectVersion = exports.ENVIRONMENTAL_BONUS_MANUFACTURER = exports.CONTACT_FORM_BUTTON_ID = exports.APPLICATION_READY_EVENT_ID = exports.APPLICATION_ID = exports.SubBrand = exports.BUTTON_TYPE = exports.OCAPI_CONTAINER = exports.OCAPI_BUTTON_TYPE = exports.CUSTOMER_TYPE = exports.FINANCE_TYPE = exports.APP_BRAND = exports.APP_TYPE = exports.APP_STATUS = void 0;
exports.injectHTMLProps = injectHTMLProps;
const types_1 = require("@/store/modules/vmds/types");
var APP_STATUS;
(function (APP_STATUS) {
    APP_STATUS["OK"] = "ok";
    APP_STATUS["ERROR"] = "error";
    APP_STATUS["WARNING"] = "warning";
    APP_STATUS["LOADING"] = "loading";
})(APP_STATUS || (exports.APP_STATUS = APP_STATUS = {}));
var APP_TYPE;
(function (APP_TYPE) {
    APP_TYPE["SIMPLE_CALCULATOR"] = "single";
    APP_TYPE["MODEL_SELECTOR"] = "multiple";
})(APP_TYPE || (exports.APP_TYPE = APP_TYPE = {}));
var APP_BRAND;
(function (APP_BRAND) {
    APP_BRAND["MERCEDES_BENZ_CARS"] = "cars";
    APP_BRAND["VANS"] = "vans";
})(APP_BRAND || (exports.APP_BRAND = APP_BRAND = {}));
var FINANCE_TYPE;
(function (FINANCE_TYPE) {
    FINANCE_TYPE["LEASING"] = "leasing";
    FINANCE_TYPE["FINANCING"] = "financing";
})(FINANCE_TYPE || (exports.FINANCE_TYPE = FINANCE_TYPE = {}));
var CUSTOMER_TYPE;
(function (CUSTOMER_TYPE) {
    CUSTOMER_TYPE["PRIVATE"] = "private";
    CUSTOMER_TYPE["BUSINESS"] = "business";
})(CUSTOMER_TYPE || (exports.CUSTOMER_TYPE = CUSTOMER_TYPE = {}));
var OCAPI_BUTTON_TYPE;
(function (OCAPI_BUTTON_TYPE) {
    OCAPI_BUTTON_TYPE["CAR_CONFIGURATOR"] = "car_configurator";
    OCAPI_BUTTON_TYPE["TEST_DRIVE"] = "test_drive";
    OCAPI_BUTTON_TYPE["SHOWROOM_STOCK"] = "showroom_stock";
    OCAPI_BUTTON_TYPE["DEALER_LOCATOR"] = "dealer_locator";
    OCAPI_BUTTON_TYPE["CLOSE"] = "close";
})(OCAPI_BUTTON_TYPE || (exports.OCAPI_BUTTON_TYPE = OCAPI_BUTTON_TYPE = {}));
var OCAPI_CONTAINER;
(function (OCAPI_CONTAINER) {
    OCAPI_CONTAINER["OUTPUT"] = "output";
    OCAPI_CONTAINER["REGULATORY"] = "regulatory";
    OCAPI_CONTAINER["FULL_SUMMARY"] = "fullSummary";
    OCAPI_CONTAINER["SHORT_SUMMARY"] = "shortSummary";
})(OCAPI_CONTAINER || (exports.OCAPI_CONTAINER = OCAPI_CONTAINER = {}));
var BUTTON_TYPE;
(function (BUTTON_TYPE) {
    BUTTON_TYPE["DEFAULT"] = "default";
    BUTTON_TYPE["CALL_TO_ACTION"] = "callToAction";
    BUTTON_TYPE["LIST"] = "list";
})(BUTTON_TYPE || (exports.BUTTON_TYPE = BUTTON_TYPE = {}));
var SubBrand;
(function (SubBrand) {
    SubBrand["MERCEDES_AMG"] = "MERCEDES_AMG";
    SubBrand["MERCEDES_EQ"] = "MERCEDES_EQ";
    SubBrand["MERCEDES_MAYBACH"] = "MERCEDES_MAYBACH";
    SubBrand["SMART"] = "SMART";
})(SubBrand || (exports.SubBrand = SubBrand = {}));
exports.APPLICATION_ID = 'fcis-oneweb';
exports.APPLICATION_READY_EVENT_ID = 'owdc-ready';
exports.CONTACT_FORM_BUTTON_ID = 'lead-form';
exports.ENVIRONMENTAL_BONUS_MANUFACTURER = 'environmentalBonusManufacturer';
const injectVersion = (version) => {
    const fcisOWDCConfig = window.fcisConfig || {};
    fcisOWDCConfig.version = version;
    window.fcisOWDCConfig = fcisOWDCConfig;
};
exports.injectVersion = injectVersion;
function injectHTMLProps(language) {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
        htmlElement.setAttribute('dir', 'ltr');
        if (language) {
            htmlElement.setAttribute('lang', language);
        }
    }
}
const inputFormatting = (value, formatting, input) => {
    var _a;
    const decimalPlaces = !isNaN(formatting.decimalPlaces) ? formatting.decimalPlaces : 2;
    const values = parseFloat(value).toFixed(decimalPlaces).toString().split('.');
    const temp = [];
    for (let i = values[0].length; 0 < i; i -= 3) {
        i <= 3 ? temp.unshift(values[0].slice(0, i)) : temp.unshift(values[0].slice(i - 3, i));
    }
    if (input) {
        if (input.subtype === 'Mileage') {
            let milageValue = '';
            temp.forEach((val, index) => {
                if (index !== temp.length - 1) {
                    milageValue += `${val}.`;
                }
                else {
                    milageValue += `${val}`;
                }
            });
            milageValue += ` ${input.value.unit}`;
            return milageValue;
        }
    }
    const zero = '0';
    const decimalPlaceholder = (_a = formatting.decimalPlaceholder) !== null && _a !== void 0 ? _a : zero.repeat(decimalPlaces);
    let finalValue = !formatting.currencyPosition ? `${formatting.currency}${formatting.literal}` : '';
    temp.forEach((val, index) => {
        if (index !== temp.length - 1) {
            finalValue += `${val}${formatting.group}`;
        }
        else {
            finalValue += `${val}`;
        }
    });
    /**
     * Returns either the decimals or the placeholder
     * @param decimals Either undefined or the decimals as string
     * @param placeholder The placeholder string
     * @returns The decimals, if defined and not '00', otherwise the placeholder.
     */
    const getFormattedDecimals = (decimals, placeholder) => {
        if (decimals && decimals !== '00') {
            return decimals;
        }
        return placeholder;
    };
    if (decimalPlaceholder) {
        finalValue += `${formatting.decimal}${getFormattedDecimals(values[1], decimalPlaceholder)}`;
    }
    finalValue += formatting.currencyPosition ? `${formatting.literal}${formatting.currency}` : '';
    return finalValue;
};
exports.inputFormatting = inputFormatting;
const getValidatedValueWithMinMax = (min, max, value) => {
    const maxValue = Math.abs(Number(max) - Number(value));
    const minValue = Math.abs(Number(min) - Number(value));
    return maxValue > minValue ? min : max;
};
exports.getValidatedValueWithMinMax = getValidatedValueWithMinMax;
exports.ERROR = {
    MARKET_UNAVAILABLE: 'MARKET_UNAVAILABLE',
    VMDS_INVALID_CLASS: 'VMDS_INVALID_CLASS',
    VMDS_INVALID_BODYTYPE: 'VMDS_INVALID_BODYTYPE',
    ONECALC_UNAVAILABLE: 'ONECALC_UNAVAILABLE',
    ONECALC_ERROR: 'ONECALC_ERROR',
    NO_OUTPUT_CONTAINERS: 'NO_OUTPUT_CONTAINERS',
};
exports.ENGINE_TYPE = {
    UNDEFINED: 'UNDEFINED',
    MONO_REGULAR: 'MONO_REGULAR',
    MONO_DIESEL: 'MONO_DIESEL',
    MONO_ELECTRIC: 'MONO_ELECTRIC',
    MONO_FUELCELL: 'MONO_FUELCELL',
    BI_MILD: 'BI_MILD',
    BI_PLUGIN: 'BI_PLUGIN',
};
const getInstallmentMonths = (value) => {
    const regex = new RegExp('((.*?) )');
    return regex.test(value) ? value.replace(new RegExp('((.*?) )'), '') : '';
};
exports.getInstallmentMonths = getInstallmentMonths;
const getDescendantProp = (key, object) => {
    return key.split('.').reduce(function (a, b) {
        return a ? a[b] : {};
    }, object);
};
exports.getDescendantProp = getDescendantProp;
const isValidValue = (value) => {
    return typeof value === 'string' || typeof value === 'number';
};
exports.isValidValue = isValidValue;
const getUrlPrefix = (environment) => {
    switch (environment) {
        case 'development':
            return 'dev.';
        case 'test':
            return 'test.';
        default:
            return '';
    }
};
exports.getUrlPrefix = getUrlPrefix;
exports.INPUT_TYPES = {
    NUMBER: 'NUMBER',
    BOOLEAN: 'BOOLEAN',
    OUTPUT_TEXT: 'OUTPUTTEXT',
    ONE_OF_MANY: 'ONEOFMANY',
};
/**
 * Won't be smooth in Safari, but we will address this behavior in he animation ticket
 */
const smoothScrollTo = (target) => {
    target.scrollIntoView({ behavior: 'smooth' });
};
exports.smoothScrollTo = smoothScrollTo;
const appendMeta = (name, value) => {
    const meta = document.createElement('meta');
    meta.setAttribute(name, value);
    document.head.appendChild(meta);
};
exports.appendMeta = appendMeta;
const appendScript = (url, attributes = null) => {
    const scriptElement = document.querySelector(`script[src="${url}"]`);
    if (scriptElement !== null)
        return Promise.resolve(true);
    return new Promise((resolve, reject) => {
        const element = document.createElement('script');
        element.setAttribute('async', '');
        element.setAttribute('src', url);
        if (attributes) {
            Object.keys(attributes).forEach((attributeKey) => {
                element.setAttribute(attributeKey, attributes[attributeKey] || '');
            });
        }
        element.onload = () => resolve(true);
        element.onerror = () => reject(new Error('Failed to load injected script element'));
        document.head.append(element);
    });
};
exports.appendScript = appendScript;
const isDemoMarket = (locale) => {
    return locale.indexOf('DX') > -1;
};
exports.isDemoMarket = isDemoMarket;
const isHybrid = (engineConcept) => {
    return engineConcept === types_1.EngineConcept.PLUGIN_HYBRID;
};
exports.isHybrid = isHybrid;
const isHybridCCS = (engineConcept) => {
    return engineConcept === types_1.EngineConceptCCS.HYBRID;
};
exports.isHybridCCS = isHybridCCS;
const isElectricCCS = (engineConcept) => {
    return engineConcept === types_1.EngineConceptCCS.ELECTRIC;
};
exports.isElectricCCS = isElectricCCS;
/**
 * helper function that converts vmds FuelType values into acceptable CCS api FuelType values
 * @param fuelType
 */
const getCCSFuelType = (fuelType) => {
    switch (fuelType) {
        case types_1.FuelType.DIESEL:
            return types_1.FuelTypeCCS.DIESEL;
        case types_1.FuelType.PETROL:
            return types_1.FuelTypeCCS.SUPER;
        case types_1.FuelType.PETROL_PLUS:
            return types_1.FuelTypeCCS.SUPERPLUS;
        case types_1.FuelType.ETHANOL:
            return types_1.FuelTypeCCS.SUPER_E10;
        case types_1.FuelType.ELECTRIC:
            return types_1.FuelTypeCCS.ELECTRIC;
        default:
            return types_1.FuelTypeCCS.SUPER;
    }
};
exports.getCCSFuelType = getCCSFuelType;
/**
 * helper function that converts vmds EngineConcept values into acceptable CCS api EngineConcept values
 * @param engineConcept
 */
const getCCSEngineConcept = (engineConcept) => {
    switch (engineConcept) {
        case types_1.EngineConcept.COMBUSTION:
            return types_1.EngineConceptCCS.COMBUSTOR;
        case types_1.EngineConcept.ELECTRIC:
            return types_1.EngineConceptCCS.ELECTRIC;
        case types_1.EngineConcept.PLUGIN_HYBRID:
            return types_1.EngineConceptCCS.HYBRID;
        case types_1.EngineConcept.MILD_HYBRID:
            return types_1.EngineConceptCCS.COMBUSTOR;
        default:
            return types_1.EngineConceptCCS.COMBUSTOR;
    }
};
exports.getCCSEngineConcept = getCCSEngineConcept;
const getValidPriceTag = (vehicle, isBusiness) => {
    const getPriceTagsOrder = (isBusiness) => isBusiness ? ['netPrice', 'listPrice'] : ['listPrice', 'netPrice'];
    const priceOrder = getPriceTagsOrder(isBusiness);
    const validPriceTag = priceOrder.find((priceTag) => vehicle[priceTag]) || '';
    return validPriceTag;
};
exports.getValidPriceTag = getValidPriceTag;
const avoidCarsWithNoPrice = (classes) => {
    const findValidVehicles = ({ listPrice, netPrice }) => netPrice || listPrice;
    const findValidBodies = ({ vehicles }) => vehicles.some(findValidVehicles);
    const findValidClasses = ({ bodies }) => bodies.some(findValidBodies);
    return classes.filter(findValidClasses).map((eachClass) => (Object.assign(Object.assign({}, eachClass), { bodies: eachClass.bodies.filter(findValidBodies).map((body) => (Object.assign(Object.assign({}, body), { vehicles: body.vehicles.filter(findValidVehicles) }))) })));
};
exports.avoidCarsWithNoPrice = avoidCarsWithNoPrice;
exports.plsMarkets = ['DE', 'AT', 'SE'];
